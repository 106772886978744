import React from 'react';
import Layout from '../layouts/layout/Layout';
import './jobDescription.scss';
// @ts-ignore
import mestekLogo from './../images/mestek-logo.svg';
import { createMedia } from '@artsy/fresnel';
import { Container } from 'semantic-ui-react';
// @ts-ignore
import bookmark from './../images/bookmark.svg';
// @ts-ignore
import rightArrow from './../images/right-arrow.svg';
// @ts-ignore
import staticMap from './../images/staticmap.png';
import Footer from '../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import favicon from './../images/favicon.png';

const { MediaContextProvider, Media } = createMedia({
	breakpoints: {
		mobile: 0,
		tablet: 768,
		computer: 1200
	}
});

const menuItems = [
	{ name: 'Home', href: '/' },
	{ name: 'Job Opportunities', href: '/search' },
	{ name: 'Benefits', href: '/benefits' }
];

const JobDescription = ({ pageContext }: any) => {
	return (
		<Layout
			media={Media}
			mediaContextProvider={MediaContextProvider}
			logoSrc={mestekLogo}
			menuItems={menuItems}
		>
			<Helmet>
				<title>{pageContext.title}</title>
				<meta
					name="description"
					content={
						'Explore job opportunities at Mestek, Inc., a family of manufacturers of HVAC equipment and automated manufacturing machinery with a legacy of innovation.'
					}
				/>
				<link
					rel="icon"
					type="image/png"
					href={favicon}
					sizes="32x32"
				/>
			</Helmet>
			<div className={'desc-hero'}>
				<div className={'container-xxl'}>
					<span className={'mt4'}>Job Listings</span>
					<div className={'title r afs pt3 pb2'}>
						<img
							style={{ alignSelf: 'flex-start' }}
							src={bookmark}
							alt={'Bookmark icon'}
						/>
						<h1>{pageContext.title}</h1>
					</div>
				</div>
			</div>
			<div className={'container-xxl description mb6'}>
				<div className={'desc w100-xl'}>
					<h2 className={'job-desc'}>Job Description</h2>
					<a
						className="dn d-f-xl"
						href={pageContext.url}
						target={'_blank'}
					>
						<button>
							APPLY NOW{' '}
							<img src={rightArrow} alt={'Apply Now Arrow'} />
						</button>
					</a>
					<h3 className="dn d-f-xl">Location:</h3>
					<p className="dn d-f-xl">
						{pageContext.city}, {pageContext.state}
					</p>
					<p className="dn d-f-xl"># {pageContext.reqNumber}</p>
					<hr />
					<h3>ESSENTIAL FUNCTIONS:</h3>
					<p
						dangerouslySetInnerHTML={{
							__html: pageContext.description
						}}
					/>
					<hr />
					<h3>Requirements:</h3>
					<p
						dangerouslySetInnerHTML={{
							__html: pageContext.requirements
						}}
					/>

					{/*<h1>ReqNumber: {pageContext.reqNumber}</h1>*/}
					{/*<h1>Company: {pageContext.company}</h1>*/}
					{/*<h1>lat: {pageContext.lat}</h1>*/}
					{/*<h1>lng: {pageContext.lng}</h1>*/}
					{/*<h1>JobPath: {pageContext.jobPath}</h1>*/}
					{/*<h1>Application URL: <a href={pageContext.url}>{pageContext.url}</a></h1>*/}
					<a
						href={pageContext.url}
						className={'ApplyButton ApplyButtonBottom'}
						target={'_blank'}
					>
						<button>
							APPLY NOW{' '}
							<img src={rightArrow} alt={'Apply Now Arrow'} />
						</button>
					</a>
				</div>
				<aside className={'aside d-n-xl'}>
					<div className={'box'}>
						<a
							href={pageContext.url}
							className={'ApplyButton ApplyButtonTop'}
							target={'_blank'}
						>
							<button>
								APPLY NOW{' '}
								<img src={rightArrow} alt={'Apply Now Arrow'} />
							</button>
						</a>
						<h3>Location:</h3>
						<p>
							{pageContext.city}, {pageContext.state}
						</p>
						<p># {pageContext.reqNumber}</p>
					</div>
				</aside>
			</div>
			{/*<div className={'container-xxl'}>*/}
			{/*<img className={'static-map'} src={staticMap}*/}
			{/*	 alt={`Map of the ${pageContext.city}, ${pageContext.state}`} />*/}
			{/*</div>*/}
		</Layout>
	);
};

export default JobDescription;
